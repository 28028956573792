import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import pandaAzul from '../assets/img/gato-azul.png';
import pandaRojo from '../assets/img/panda-rojo.png';
import fondo from '../assets/img/fondo-retro.png';

const Success = () => {
  const bgPanda = {
    backgroundImage: `url(${fondo})`
  }
  const handleClick = () => {
    window.location.href="https://api.whatsapp.com/send?phone=34674456983";
  }
  return (
     <Container style={bgPanda} className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <Row className="text-center">
        <Col>
           <img src={pandaAzul} alt="panda" width={250} />
        </Col>
        <Col >

          <h1 className="dunkin blue display-1">SUCCESS!</h1>
          <p className="varela blue lead">
            Hemos recibido todos los datos y tu grupo está confirmado.
            ¡Nos hace mucha ilusión verte pronto!
          </p>
          <p className="dunkin mt-2 blue">Escríbenos un What'sApp si necesitas algo más</p>
          <Button 
          className="mt-4 form-button" 
          onClick={handleClick}
          style={{ borderColor: '#24406b', color: '#24406b' }}
          >WhatsApp</Button>
        </Col>
        <Col>
        <img src={pandaRojo} alt="panda" width={250} />
        </Col>
      </Row>
     </Container>

  );
};

export default Success;

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Success from './components/Success';
import Canceled from './components/Canceled';
import RegisterForm from './components/RegistrationForm/RegisterForm'
import CourseSelection from './components/CourseSelection/CourseSelection'
import useFetch from './components/useFetch';

function App() {
  const [userId, setUserId] = useState('')
  const {courses} = useFetch()
  const [formCompleted, setFormCompleted] = useState(false)
  return (
    <Router>
      <Switch>
        <Route path="/success">
          <Success />
        </Route>
        <Route path="/canceled">
          <Canceled />
        </Route>
        <Route path="/">
          <RegisterForm setUserId={setUserId} setFormCompleted={setFormCompleted} />
          <CourseSelection value={userId} courses={courses} formCompleted={formCompleted} />
        </Route>

      </Switch>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

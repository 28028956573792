import React, { useState, useRef } from 'react'
import { useForm } from "react-hook-form";
import { Form, Button, Container, Col, Row, Tooltip, Overlay, Modal } from 'react-bootstrap'
import '../../css/global.css'
import panda from '../../assets/img/panda.png';
import gato from '../../assets/img/gato.svg';

import Terms from './Terms'
import api from '../../utils/axiosConfig'
const RegisterForm = (props) => {
    const target = useRef(null);
    const [disable, setDisable] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: "onChange" });
    const onSubmit = data => {
        api.post('/form-create', {
            data
        }).then((response) => {
            console.log('success')
            setDisable(true)
            setShowToast(true)
            props.setUserId(response.data.insertId)
            props.setFormCompleted(true)
        });
    };

    return (
        <Container>
            <Row>
                <Col className="mt-5">
                    <h1 className="dunkin blue">Incripción Online</h1>
                    <p className="dunkin font-light text-muted">Cursos de B1B2 Academy</p>

                </Col>
                <Col>

                </Col>
            </Row>
            <Row className="form-content font-regular">

                <Col>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="ml-2"
                    >
                        <Row className="mb-2">
                            <Form.Group controlId="formfirstName">
                                <Form.Label className="">Nombre *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    className="form-labels"
                                    {...register("firstname",
                                        { required: true, maxLength: 80 })}
                                    style={{ border: errors.firstname ? '3px solid red' : '' }}
                                />
                                {errors.firstname && <p>This is required</p>}
                            </Form.Group>

                            <Form.Group className="" controlId="formLastName">
                                <Form.Label>Apellidos *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    className="form-labels"
                                    {...register("lastname", { required: true, maxLength: 100 })}
                                    style={{ border: errors.lastname ? '3px solid red' : '' }}
                                />
                            </Form.Group>
                            <img src={gato} alt="gato" height={80} className="cat-type" />
                        </Row>
                        <Row className="mb-2">
                            <Form.Group className="" controlId="formDni">
                                <Form.Label>DNI *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    className="form-labels"
                                    {...register("dni", { required: true, maxLength: 80 })}
                                    style={{ border: errors.dni ? '3px solid red' : '' }}

                                />
                            </Form.Group>

                            <Form.Group className="" controlId="formAddress">
                                <Form.Label>Dirección *</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-labels"
                                    {...register("address", { required: true, maxLength: 100 })}
                                    style={{ border: errors.address ? '3px solid red' : '' }}

                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group className="" controlId="formCity">
                                <Form.Label>Localidad *</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-labels"
                                    {...register("city", { required: true, maxLength: 80 })}
                                    style={{ border: errors.city ? '3px solid red' : '' }}

                                />
                            </Form.Group>



                            <Form.Group className="" controlId="formEmail">
                                <Form.Label>Email *</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-labels"
                                    {...register("email",
                                        { required: true, pattern: /^\S+@\S+$/i })}
                                    style={{ border: errors.email ? '3px solid red' : '' }}
                                />
                            </Form.Group>


                            <Form.Group className="" controlId="formPhone">
                                <Form.Label>Número de teléfono *</Form.Label>
                                <Form.Control
                                    type="tel"
                                    className="form-labels"
                                    {...register("phone", { required: true, minLength: 6, maxLength: 12, pattern: /^[0-9 ()+-]+$/ })}
                                    style={{ border: errors.phone ? '3px solid red' : '' }}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group className="" controlId="formQuestion">
                                <Form.Label>¿Cómo nos has conocido? *<span role="img" aria-label="lupa">🧐</span> </Form.Label>
                                <Form.Control className="form-labels" {...register("question", {})} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="pb-3" controlId="formGender">
                                <Form.Label>Sexo</Form.Label>
                                <div /* onChange={(e) => { setPaymentMethod(e.target.value) }} */>
                                    <Form.Check
                                        type="radio"
                                        name="formHorizontalRadios"
                                        className=""
                                        value="1"
                                        label="Hombre"
                                    />

                                    <Form.Check
                                        type="radio"
                                        name="formHorizontalRadios"
                                        className=""
                                        value="2"
                                        label="Mujer"
                                        def
                                        aultChecked="true"
                                    />
                                </div>
                            </Form.Group>
                        </Row>
                        <Row className="">
                            <Col>
                                <Form.Group className="" controlId="formQuestion">
                                    <Form.Check
                                        className="pl-3"
                                        type="checkbox"
                                        {...register("checkbox", { required: true })}
                                        style={{ border: errors.checkbox ? '3px solid red' : '' }}
                                    />
                                    <Form.Label className="underline pl-4" onClick={handleShow}>Acepto los términos y condiciones</Form.Label>
                                </Form.Group>

                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton={true}>
                                        <Modal.Title>Términos y condiciones</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body><Terms /></Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                            <Col className="align-items-center justify-content-center text-center">
                                <Button
                                    type="submit"
                                    disabled={disable || !isValid}
                                    className="form-button"
                                    style={isValid ? { borderColor: 'green', color: 'green' } : {}}
                                    ref={target}
                                >
                                    <i
                                        style={{ color: isValid || !disable ? 'green' : '' }}
                                        className={isValid || disable ? 'fa-lg fas fa-arrow-right arrow1 mr-3' : ''}
                                    />
                                    CONTINUAR
                                </Button>
                                <Overlay target={target.current} show={showToast} placement="right">
                                    {(props) => (
                                        <Tooltip {...props}>
                                            Continua hacia abajo! <span role="img" aria-label="glasses">😎</span>!
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </Col>
                        </Row>

                        <Row className="align-items-center justify-content-center">

                        </Row>
                    </Form>
                </Col>

            </Row>
            <Col className="align-items-center justify-content-center d-flex">
                <img src={panda} alt="panda" width={300} />
            </Col>


        </Container >
    )
}
export default RegisterForm
import { useEffect, useState } from "react";
import axios from "axios";

function useFetch(props) {
  const [courses, setCourses] = useState([])
  const url = "https://dkmwdxc6g4lk7.cloudfront.net/b1b2-enrollment/enrollment-data.json";
  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        console.log(response.data)
        console.log(typeof response.data)
        setCourses(response.data)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log("Successfully fetched courses data from external json");
      });
  }, [url]);


  return { courses };
}

export default useFetch;
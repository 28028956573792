import React from 'react'


const Terms = () => {
    return (
        <p>
            <p>Por B1 B2 Academy<br />
                Empresa: B1 B2 Academy<br />
                RAZÓN SOCIAL: Loto Gómez-Aleixandre Sandoval CIF/NIF: 75163264 J<br />
                Domicilio en: AVD. CONSTITUCION Nº18 ESC 4 PL 01 CP: 18012 Localidad: GRANADA<br />
                CONDICIONES GENERALES<br />
                1.- El contrato de matrícula tendrá una duración de un año, con independencia del tipo de curso que solicite.<br />
                2.- Cursos anuales: Los cursos anuales tienen una matrícula de 20€. Las mensualidades se abonarán por meses anticipados, dentro de los diez primeros días de cada mes, entregándose el correspondiente recibo del centro firmado y sellado. El alumno que por cualquier motivo justificado abandonase el curso deberá comunicarlo por escrito antes del día 20 del mes anterior al de la baja. De no hacerlo así, deberá abonar la mensualidad correspondiente.El alumno que comience las clases durante la primera quincena del mes abonará los honorarios de éste en su totalidad. Si el inicio de las clases se realizase en la segunda quincena, el alumno abonará media mensualidad.<br />
                3.- En el caso de cursos intensivos, semi-intensivos, descuentos u ofertas, los cursos se abonarán al completo al inicio de los mismos. Todos los cursos tendrán una reserva de plaza de 45€, ya incluida en el precio del curso. En estos cursos los cambios de grupo se podrán realizar durante la primera semana de curso, adjuntando justificante (médico, laboral o de naturaleza análoga) del motivo del cambio.<br />
                4.- Los días festivos, así como los periodos vacacionales de Navidad (24/12 al 06/01) y Semana Santa se consideran como días no lectivos dentro del curso.<br />
                5.- Si por cualquier motivo atribuible al Centro tuviese que interrumpirse cualquier tipo de curso antes de lo previsto, el Centro lo comunicará al alumnado, reintegrándole la parte proporcional abonada con anterioridad y que estuviese pendiente de ejecución. Si el motivo fuese atribuible al alumno, cualquier cancelación durante los 3 días anteriores al inicio del curso, así como durante el desarrollo de éste, tendrán un coste del 30% del importe proporcional reembolsable.<br />
                6.- En el caso de que un alumno de clases particulares anule una clase, deberá hacerlo con al menos 24 horas de antelación. En caso contrario deberá abonar la clase a la que no ha asistido.<br />
                7.- El Centro devolverá las cantidades aportadas en concepto de reserva/matrícula, en el supuesto de que el curso en el que el alumno se matriculó no se formalice por causas no imputables al alumno.De no ser así, ni las matrículas ni las reservas de plaza serán reembolsables.<br />
                8.- El alumno, una vez finalizado el curso, obtendrá el correspondiente certificado de asistencia de carácter privado, emitido por este centro, siempre a petición del alumno y teniendo en cuenta que esta enseñanza no oficial y no conducente a la obtención de un título con carácter oficial o certificado de profesionalidad.<br />
                9.- El alumno mantendrá en todo momento un comportamiento correcto que no obstaculice el normal desarrollo del curso.<br />
                10.- El alumno se compromete a tener una asistencia regular a clase (mínimo 80%) para que de esta forma el curso se desarrolle con normalidad.<br />
                11.- Las personas usuarias tienen derecho a solicitar la entrega de factura por el importe total de las enseñanzas o servicios recibidos, así como por las cantidades que se entregan parcialmente a cuenta de las mismas.<br />
                12.- La comunicación con el alumnado se realizará por vía telefónica, email, whatsapp o por correo ordinario, pudiendo utilizar estos medios para fines promocionales. Si no desea formar parte del fichero de B1 B2 Academy deberá de formalizarlo por escrito.<br />
                13.- De acuerdo con lo dispuesto en el artículo 5 de la Ley Orgánica 15/1999 de Protección de Datos de Carácter Personal, los datos del alumno pasan a formar parte del fichero de Alumnos de B1 B2 Academy para ser tratados con la exclusiva finalidad propia del contrato de matrícula. El hecho de firmar el contrato de matrícula implica que el alumno reconoce que la información y los datos personales que nos indica son suyos, exactos y ciertos. Puede ejercitar los derechos de acceso, rectificación y cancelación de sus datos, dirigiéndose por escrito a B1B2 Academy, Avda. Constitución nº18 ESC 04 PL01 CP 18012 Granada.<br />
                B1B2 ACADEMY se reserva el derecho de utilizar el material gráfico obtenido durante el desarrollo de las actividades extraescolares (fotografías, vídeos, trabajos plásticos…) para difusión de éste u otros cursos, en folletos, vídeos, carteles, página web, etc.<br />
                14.- Las partes aceptan someterse al ámbito de los Tribunales de Granada, para cuantas interpretaciones pudieran plantearse de contenido del presente contrato.<br />
                15.- De acuerdo con lo establecido en el Decreto 625/2019, de 27 de diciembre, por el que se regulan los derechos a la información y a la protección de los intereses económicos de las personas usuarias de centros privados que impartan enseñanzas no oficiales. El alumno recibirá un folleto informativo de precios del curso o un correo electrónico. Se adjuntan en la matricula todas las promociones de todos los cursos, que están disponibles para el alumno.</p>
        </p>
    );
}
export default Terms;
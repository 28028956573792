import React from 'react'
import { Card } from 'react-bootstrap'
import '../css/normalize.css'
function Package({ title, price, text }) {
    return (
        <Card xs={1} style={{ width: '18rem' }} className="text-center pricing-card m-0 ">
            <Card.Body className="text-light p-0">
                <Card.Title className="blue dunkin mb-0">{title}</Card.Title>
                <Card.Title>{price} <h2 className="blue">{price}€</h2></Card.Title>
                <Card.Text className="text-secondary mb-0">
                    {text}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Package

import React, { useState, useRef } from 'react'
import { DropdownButton, Dropdown, Container, Row, Col, Button, Overlay, Tooltip } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Package from '../Package'
import api from '../../utils/axiosConfig'
// import courses from '../Courses'
const CourseSelection = (props) => {
    const target = useRef(null);
    const [course, setCourse] = useState("")
    const [schedule, setSchedule] = useState("")
    const [paymentMethod, setPaymentMethod] = useState("")
    const [amount, setAmount] = useState("")
    const [paymentUrl, setPaymentUrl] = useState("")
    const [disable, setDisable] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(0)
    const [showToast, setShowToast] = useState(false);


    // Fetch courses data from S3-> Cloudfront
    // const url = "https://v2.jokeapi.dev/joke/Any";
    // const url = "https://dkmwdxc6g4lk7.cloudfront.net/b1b2-enrollment/enrollment-data.json";

    const valueFilter = Object.values(props.courses).filter(entry => entry.title === course);



    // Logic for post request
    const handleSubmit = () => {
        console.log(props.formCompleted)
        console.log(buttonClicked)
        if (buttonClicked < 3 || props.formCompleted === false) {
            setShowToast(true)
            
        } else {
            console.log("allowed")
            api.post('/schedule-details', {
                course: course,
                schedule: schedule,
                paymentmethod: paymentMethod,
                amount: amount,
                userid: props.value
            }).then((response) => {
                console.log(response)
                setDisable(true)
                window.location.href = paymentUrl
            });
        }
    }


    return (
        <Container>
            <Row className="mb-5" >
                <Col>
                    <div className="card-selector text-center">
                        <h1 className="varela text-white p-3">Selecciona tu curso y tu nivel</h1>
                        <Dropdown className="mt-0">
                            <p className="valera text-white mb-0 pb-0">Curso</p>
                            <DropdownButton
                                id="dropdown-button"
                                title={course}
                                className=""
                            >
                                {props.courses.map((option) => (
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            setCourse(e.target.textContent)
                                            setButtonClicked(buttonClicked + 1)
                                        }}
                                        key={option.id}
                                    >
                                        {option.title}
                                    </Dropdown.Item>
                                ))}

                            </DropdownButton>
                        </Dropdown>

                        <Dropdown className="pb-2">
                            <p className="valera text-white mb-0 pb-0">Horario</p>
                            <DropdownButton
                                id="dropdown-button"
                                title={schedule}
                                className=""
                            >
                                {valueFilter?.map(entry => {
                                    return (
                                        entry.schedule.map((secondEntry) => (
                                            <Dropdown.Item
                                                onClick={
                                                    (e) => {
                                                        setSchedule(e.target.textContent)
                                                        setButtonClicked(buttonClicked + 1)
                                                    }
                                                }
                                                key={secondEntry}
                                            >
                                                {secondEntry}
                                            </Dropdown.Item>
                                        ))
                                    )
                                })}
                            </DropdownButton>
                        </Dropdown>
                        <Col className="pb-4">
                            <h4 className="white pt-3">Método de pago</h4>
                            <div onChange={(e) => { setPaymentMethod(e.target.value) }}>
                                {/* <Form.Check
                                    type="radio"
                                    name="formHorizontalRadios"
                                    className="white font-light"
                                    value="1"
                                    label="Adjuntar comprobante de transferencia"
                                />
 */}
                                <Form.Check
                                    type="radio"
                                    name="formHorizontalRadios"
                                    className="white font-light"
                                    value="2"
                                    label="Pago con tarjeta"
                                    defaultChecked="true"
                                />
                            </div>
                        </Col>
                        <Col className="pb-4 pt-0">
                            <h5 className="white">Tipo de pago</h5>
                            <Dropdown className="mt-0">
                                <DropdownButton
                                    id="dropdown-button"
                                    title={amount}
                                    className=""
                                >
                                    <Dropdown.Item course="1"
                                        onClick={(e) => {
                                            setAmount(valueFilter.map(entry => {
                                                return (entry.pricing.completo.price)
                                            }))
                                            setPaymentUrl(valueFilter.map(entry => {
                                                return (entry.pricing.completo.price_id)
                                            }))
                                            setButtonClicked(buttonClicked + 1)
                                        }
                                        }>Curso completo</Dropdown.Item>
                                    <Dropdown.Item course="2"
                                        onClick={(e) => {
                                            setAmount(valueFilter.map(entry => {
                                                return (entry.pricing.reserva.price)
                                            }))
                                            setPaymentUrl(valueFilter.map(entry => {
                                                return (entry.pricing.reserva.price_id)
                                            }))
                                            setButtonClicked(buttonClicked + 1)
                                        }
                                        }>
                                        Reserva</Dropdown.Item>
                                </DropdownButton>
                            </Dropdown>
                        </Col>
                    </div>
                </Col>

            </Row>
            <Row className="d-flex justify-content-center">

                <form onSubmit={handleSubmit}>
                    <Col>
                        {
                            paymentMethod === 1 ?
                                <Package
                                    title="Transferencia"
                                    text="Prefiero adjutar una transferencia"
                                    url="/success.html"
                                    price="0"
                                />
                                :
                                valueFilter.map(entry => {
                                    return (
                                        <Col key={entry.id} className="pb-1" md="auto">
                                            <Package
                                                title={entry.title}
                                                text={entry.text}
                                                price={amount}
                                            />
                                        </Col>
                                    )
                                })

                        }

                    </Col>
                    <Col>
                        <Button
                            className="text-center form-button"
                            size="lg"
                            onClick={handleSubmit}
                            disabled={disable}
                            ref={target}
                        >
                            PAGAR
                        </Button>
                    
                        
                    </Col>
                    <Col>
                    <Overlay target={target.current} show={showToast} placement="right">
                            {(props) => (
                                <Tooltip {...props}>
                                    Debes rellenar todos los campos!
                                </Tooltip>
                            )}
                        </Overlay>
                    </Col>
                </form>
            </Row>

        </Container>
    )




}

export default CourseSelection